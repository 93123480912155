import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { CssOverwritesDbModel } from '@data-models/css-overwrites.model';
import { IPartnerInformationBody, PartnerDbModel } from '@data-models/partner-db.model';
import { CustomBookingMessageTemplateDbModel } from '@data-models/templates-db.model';
import { PARTNER_ERROR_CODES, TEMPLATE_CONSTANTS_IDS } from '@constants/app.constants';
import { PartnerService } from '@services/feature-service/partner.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, forkJoin, of, switchMap } from 'rxjs';
import { HelperService } from '../helper/helper.service';
import { DomManipulationService } from '../util-service/dom-manipulation.service';
import { LoggerService } from '../util-service/logger.service';

export const PartnerResolveFn: ResolveFn<PartnerDbModel> = (
  route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {
  let _partnerData = new PartnerDbModel();
  const partnerService = inject(PartnerService);
  const domManipulationService = inject(DomManipulationService);
  const helperService = inject(HelperService);
  const translate = inject(TranslateService);
  const templateConstantsIds = TEMPLATE_CONSTANTS_IDS;

  let observables: {
    internalToken: Observable<{ success: boolean }>,
    templates: Observable<CustomBookingMessageTemplateDbModel[]>,
    cssOverwrites: Observable<CssOverwritesDbModel | null>
  } = {
    internalToken: of({ success: false }),
    templates: partnerService.getPartnerTemplates(),
    cssOverwrites: of(null)
  };

  const partnerBody: IPartnerInformationBody = {
    booking_name: (route.params?.bookingName ? route.params.bookingName : environment.defaultBookingPartnerName)
  };
  const language = (route.queryParams?.lang || route.queryParams?.language);

  route.params?.workerUsername && (partnerBody.username = route.params.workerUsername?.toLowerCase());

  if (route.queryParams?.internal === 'true' && route.queryParams?.token) {
    partnerBody.internal = true;
    observables.internalToken = partnerService.verifyInternalToken(route.queryParams.token);
  }

  if (route.queryParams?.worker_id && !isNaN(route.queryParams?.worker_id)) {
    partnerBody.worker_id = Number(route.queryParams?.worker_id);
  }

  return partnerService.getPartnersWidgetInformation(partnerBody, false).pipe(
    switchMap((partner: PartnerDbModel) => {
      _partnerData = partner;
      const selectedLang = helperService.getDefaultLanguage(language);
      translate.use(selectedLang);
      translate.setDefaultLang(selectedLang);

      domManipulationService.setOgURL(`${environment.deployUrl}${state.url}`);
      _partnerData?.landingpage_header_background_color && (domManipulationService.setThemeColor(_partnerData.landingpage_header_background_color));

      if (!partnerService.isWhitelabeledPartner) {
        domManipulationService.setAuthorMeta('Calenso', 'Calenso');
        domManipulationService.setDocumentFavicon(`${environment.deployUrl}/favicon.ico`);
        if (!state.url.includes('events')) {
          domManipulationService.setOgImageMeta(
            `${environment.deployUrl}/assets/images/logo/calenso_placeholder.png`,
            'Calenso'
          );
        }
      } else {
        domManipulationService.setAuthorMeta(_partnerData?.name, _partnerData?.name);
        if (_partnerData?.favicon_logo) {
          domManipulationService.setDocumentFavicon(`${environment.faviconImagePath}${_partnerData.uuid}/${_partnerData?.favicon_logo}`);
        }

        if (!state.url.includes('events')) {
          domManipulationService.setOgImageMeta(
            `${environment.landingPageImagePath}${_partnerData.uuid}/${_partnerData?.landingpage_logo}`,
            _partnerData?.name
          );
        }

        // Check for cusom css overwrite if is_custom_css_bookingpage_enabled is enabled and partner is whitelabled
        if (Number(partner?.is_custom_css_bookingpage_enabled) === 1) {
          observables.cssOverwrites = partnerService.getCssOverwrites();
        }
      }

      return forkJoin(observables).pipe(
        switchMap(result => {
          const metaTemplates: CustomBookingMessageTemplateDbModel[] = [];

          if (result?.templates?.length) {
            result.templates.filter(template => {
              return [
                templateConstantsIds.WHITELABEL.WHITELABEL_BOOKINGPAGE_PAGETITLE,
                templateConstantsIds.WHITELABEL.WHITELABEL_BOOKINGPAGE_DESCRIPTION_APPOINTMENT,
                templateConstantsIds.WHITELABEL.WHITELABEL_BOOKINGPAGE_DESCRIPTION_EVENT
              ].includes(template.id);
            }).forEach(template => {
              template.is_multi_language = 1;
              metaTemplates[template.id] = template;
            });

            if (metaTemplates[templateConstantsIds.WHITELABEL.WHITELABEL_BOOKINGPAGE_PAGETITLE] !== undefined) {
              domManipulationService.setDocumentTitle(metaTemplates[templateConstantsIds.WHITELABEL.WHITELABEL_BOOKINGPAGE_PAGETITLE]);
            }

            if (state.url.includes('events')) {
              if (metaTemplates[templateConstantsIds.WHITELABEL.WHITELABEL_BOOKINGPAGE_DESCRIPTION_EVENT] !== undefined) {
                domManipulationService.setDocumentDescription(metaTemplates[templateConstantsIds.WHITELABEL.WHITELABEL_BOOKINGPAGE_DESCRIPTION_EVENT]);
              }
            } else {
              if (metaTemplates[templateConstantsIds.WHITELABEL.WHITELABEL_BOOKINGPAGE_DESCRIPTION_APPOINTMENT] !== undefined) {
                domManipulationService.setDocumentDescription(metaTemplates[templateConstantsIds.WHITELABEL.WHITELABEL_BOOKINGPAGE_DESCRIPTION_APPOINTMENT]);
              }
            }
          }

          // Apply CSS overwrites if set by partner
          result?.cssOverwrites && partnerService.partnerCssOverwritesEvent.emit(result.cssOverwrites);

          partnerService.partnerLoadEvent.emit(_partnerData);
          return of(_partnerData);
        }),
        catchError(() => {
          partnerService.partnerLoadEvent.emit(_partnerData);
          return of(_partnerData);
        }),
      );
    }),
    catchError(error => {
      const selectedLang = helperService.getDefaultLanguage(language);
      translate.use(selectedLang);
      translate.setDefaultLang(selectedLang);
      _partnerData.has_active_subscription = (error.status !== PARTNER_ERROR_CODES.NOT_ACTIVE_SUBSCRIPTION);
      partnerService.partnerLoadEvent.emit(_partnerData);
      return of(_partnerData);
    }),
  );
}
